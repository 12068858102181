

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./newgallery.scss"
// import AddPhotoAlternateTwoToneIcon from '@mui/icons-material/AddPhotoAlternateTwoTone';
import HomeIcon from '@mui/icons-material/Home';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});
const BASE_URL = process.env.REACT_APP_Image_src || 'http://82.180.139.171';


const App = () => {
  const [images, setImages] = useState([]);
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isImageSelected, setIsImageSelected] = useState(false); // ...New added

  useEffect(() => {
    // Fetch images when component mounts
    getImages();
  }, []);

  const getImages = async () => {
    try {
      const response = await axiosInstance.get('/gallery/');
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
    setIsImageSelected(true); // ...New added
    
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('file', image);

    try {
      await axiosInstance.post('/gallery/upload', formData);
      // Refresh images after successful upload
      
      getImages();

      //New  added{
       // Reset image selection and name
       setImage(null);
       setIsImageSelected(false);
      setName('');
      //New  added}

    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };
  

  
  const handleCheckboxChange = (id) => {
    const updatedSelectedImages = [...selectedImages];
    const index = updatedSelectedImages.indexOf(id);

    if (index === -1) {
      updatedSelectedImages.push(id);
    } else {
      updatedSelectedImages.splice(index, 1);
    }

    setSelectedImages(updatedSelectedImages);
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.post('/gallery/', { imageIds: selectedImages });
      // Refresh images after successful delete
      getImages();
      // Clear selected images
      setSelectedImages([]);
    } catch (error) {
      console.error('Error deleting images:', error);
    }
  };

  return (
    <div className='container'>
      {/* <p className='head-Contact pt-5'><span className="text-danger " id="abt">OUR</span> <span className="text-info" id='pipe'>| </span><span className="text-warning" id='us'>GALLERY</span></p> */}

      <div className="fixed-header">
        <div className="galleryContainer">
        <div className="title ">
          <p className="head-Contact titlehead pt-5">
            <span className="text-danger" id="abt">
              OUR
            </span>{' '}
            <span className="text-info" id="pipe">
              {' '}
              |{' '}
            </span>
            <span className="text-warning" id="us">
              GALLERY
            </span>
          </p>
        </div> </div>
        <div className="homebtn">
          {/* <button type="button" className="btn btn-success">             
          </button> */}
          <a className="btn btn-danger" href="/"><HomeIcon className="icon" /></a>
        </div></div>


    {/* FETCH IMAGES */}
    <div className="scrollable-content">
<div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
        {images.map((img) => (
          <div key={img._id} className="col-6 col-sm-6 col-md-4 col-lg-3">
            <div className="card cardy mb-3">
              <div className="card-body">
            
            <input
              type="checkbox"
              onChange={() => handleCheckboxChange(img._id)}
              checked={selectedImages.includes(img._id)}className="form-check-input"
              style={{ position: 'absolute', top: '5px', left: '5px', zIndex: '1' }}
            />
            {/* <img src={`http://localhost:5000/Gallery/${img.file}`} alt={img.name}  
            className="img-fluid ImageGallery" /> */}
            <img src={`${BASE_URL}/Gallery/${img.file}`} alt={img.name}  
            className="img-fluid ImageGallery" />

           </div>
            </div>
          </div>
        ))}
        </div></div>
      

      {selectedImages.length > 0 && (
        <button className="btn btn-danger" onClick={handleDelete}>Delete Selected Images</button>
      )}


 {/* FILE UPLOAD */}
 <div className="uploadSection">
        <div className="imageUpload">
        <div className="formInput">
       
        <input type="text" placeholder="Title of image" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
      </div>

      <div className='formInput'>
        <label htmlFor="file" className="btn btn-primary"><AddCircleOutlineIcon className="icon" /></label>
        <input 
        type="file" 
        id='file' 
        style={{ display: "none" }} 
        onChange={handleFileChange} 
        className="form-control-file"
        />
      </div>
      {/* new added */}
      {/* Render upload button only when an image is selected */}
      {isImageSelected && (
      <button onClick={handleUpload} className="btn btn-success">Upload Image</button>)} {/* new added */}
      </div>
        </div>


    </div>
  );
};

export default App;


// // frontend/src/App.js

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import "./newgallery.scss"

// // import AddPhotoAlternateTwoToneIcon from '@mui/icons-material/AddPhotoAlternateTwoTone';
// import HomeIcon from '@mui/icons-material/Home';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


// const App = () => {
//   const [images, setImages] = useState([]);
//   const [name, setName] = useState('');
//   const [image, setImage] = useState(null);
//   const [selectedImages, setSelectedImages] = useState([]);

//   useEffect(() => {
//     // Fetch images when component mounts
//     getImages();
//   }, []);

//   const getImages = async () => {
//     try {
//       const response = await axios.get('/gallery/');
//       setImages(response.data);
//     } catch (error) {
//       console.error('Error fetching images:', error);
//       // if (error.response.data.message) {
//       //   alert(error.response.data.message)}
//       //   else {alert("please logout and login")}
//     }
//   };

//   const handleFileChange = (e) => {
//     setImage(e.target.files[0]);
//   };

//   const handleUpload = async () => {
//     const formData = new FormData();
//     formData.append('name', name);
//     formData.append('file', image);

//     try {
//       await axios.post('/gallery/upload', formData);
//       // Refresh images after successful upload
//       getImages();
//     } catch (error) {
//       console.error('Error uploading image:', error);
//     }
//   };
    
//   const handleCheckboxChange = (id) => {
//     const updatedSelectedImages = [...selectedImages];
//     const index = updatedSelectedImages.indexOf(id);

//     if (index === -1) {
//       updatedSelectedImages.push(id);
//     } else {
//       updatedSelectedImages.splice(index, 1);
//     }

//     setSelectedImages(updatedSelectedImages);
//   };

//   const handleDelete = async () => {
//     try {
//       await axios.post('/gallery/', { imageIds: selectedImages });
//       // Refresh images after successful delete
//       getImages();
//       // Clear selected images
//       setSelectedImages([]);
//     } catch (error) {
//       console.error('Error deleting images:', error);
//       // alert('your session expired, Please logout and Login Again..')
//     }
//   };


//   return (
//     <div className="container">
//       {/* GalleryContainer and Homebtn fixed at the top */}
//       <div className="fixed-header">
//         <div className="galleryContainer">
//         <div className="title ">
//           <p className="head-Contact titlehead pt-5">
//             <span className="text-danger" id="abt">
//               OUR
//             </span>{' '}
//             <span className="text-info" id="pipe">
//               {' '}
//               |{' '}
//             </span>
//             <span className="text-warning" id="us">
//               GALLERY
//             </span>
//           </p>
//         </div> </div>
//         <div className="homebtn">
//           {/* <button type="button" className="btn btn-success">             
//           </button> */}
//           <a className="btn btn-success" href="/"><HomeIcon className="icon" /></a>
//         </div></div>
      

//       {/* FETCHING IMAGES */}
//       {/* <div className="row">
//         {images.map((img) => (
//           <div key={img._id} className="col-lg-3 col-md-4 col-sm-6">
//             <div className="card mb-3">
//               <div className="card-body">
//                 <input
//                   type="checkbox"
//                   onChange={() => handleCheckboxChange(img._id)}
//                   checked={selectedImages.includes(img._id)}
//                   className="form-check-input"
//                 />
//                 <img
//                   src={`http://localhost:5000/Gallery/${img.file}`}
//                   alt={img.name}
//                   style={{ width: '100%', height: 'auto' }}
//                   className="img-fluid"
//                 />
//               </div>
//             </div>
//           </div>
//         ))}
//       </div> */}

//  {/* IMAGE GALLERY */}
//    {/* FETCHING IMAGES */}
//    <div className="scrollable-content">
// <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
//         {images.map((img) => (
//           <div key={img._id} className="col-6 col-sm-6 col-md-4 col-lg-3">
//             <div className="card cardy mb-3">
//               <div className="card-body">
//                 <input
//                   type="checkbox"
//                   onChange={() => handleCheckboxChange(img._id)}
//                   checked={selectedImages.includes(img._id)}
//                   className="form-check-input"
//                   style={{ position: 'absolute', top: '5px', left: '5px', zIndex: '1' }}
//                 />

//                 <img
//                   src={`http://localhost:5000/Gallery/${img.file}`}
//                   alt={img.name}
//                   className="img-fluid ImageGallery"
//                 />
//               </div>
//             </div>
//           </div>
//         ))}
//       </div></div>
//       {/* DELETE BUTTON */}
//       {selectedImages.length > 0 && (
//         <button onClick={handleDelete} className="btn btn-danger">Delete Selected Images</button>
//       )}
   

//     {/* UPLOAD IMAGE SECTION */}
//     <div className="uploadSection">
//         <div className="imageUpload">
//         <div className="formInput">
//           <label>Name:</label>
//           <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" />
//         </div>

//         <div className="formInput">
//           <label htmlFor="file" className="btn btn-primary">
//            <AddCircleOutlineIcon className="icon" />  </label>
//             <input
//               type="file"
//               id="file"
//               style={{ display: 'none' }}
//               onChange={handleFileChange}
//               className="form-control-file"/>       
//         </div>
//         <button onClick={handleUpload} className="btn btn-success">Upload Image</button>
//       </div>
//         </div>
     
//     </div>
//   );}
      

// export default App;

// frontend/src/App.js
