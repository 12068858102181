import "../Lists/lists.scss";
import Sidebar from "../../Components/Sidebar/Sidebar.js"
import Navbar from "../../Components/Navbar/Navbar.js"
import Datatable from "../../Components/Datatable/Datatable.js"

const List = ({columns}) => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <Datatable columns={columns}/>
      </div>
    </div>
  )
}

export default List