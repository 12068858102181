import React, { useState } from 'react'
import {membersInputs} from "../../formsource";
import axios from "axios";
import Navbar from '../../Components/Navbar/Navbar';
import Sidebar from '../../Components/Sidebar/Sidebar';
import "./newmembers.scss"

//const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});


const NewMembers = () => {
    const [info, setInfo] = useState({});
   
    
    const handleChange = (e) => {
      setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };
    
    console.log(info)
  
    const handleClick = async (e) => {
      e.preventDefault();
      try {
        await axios.post('/members', { ...info});
       setInfo( );
        // alert("Members details has been added successfully!")
        
      } catch (err) {
        console.log(err)
        alert('your session expired, Please logout and Login Again..');
      }
    };

  return (
    <div className="new">
    <Sidebar />
    <div className="newContainer">
      <Navbar />
      <div className="top">
        <h1>Add New Member</h1>
      </div>
      <div className="bottom">
        <div className="right">
          <form>
            {membersInputs.map((input) => (
              <div className="formInput" key={input.id}>
                <label>{input.label}</label>
                <input
                  id={input.id}
                  type={input.type}
                  placeholder={input.placeholder}
                  value={info[input.id] || ''}
                  onChange={handleChange}
                />
              </div>
            ))}
            
            <button onClick={handleClick}>Send</button>
            
          </form>
        </div>
      </div>
    </div>
  </div>
  )
}

export default NewMembers
