export const userInputs = [
    {
      id: "username",
      label: "Username",
      type: "text",
      placeholder: "john_doe",
    },
    {
      id: "email",
      label: "Email",
      type: "email",
      placeholder: "john_doe@gmail.com",
    },
    {
      id: "phone",
      label: "Phone",
      type: "text",
      placeholder: "+1 234 567 89",
    },
    {
      id: "password",
      label: "Password",
      type: "password",
    },
    {
      id: "country",
      label: "Country",
      type: "text",
      placeholder: "USA",
    },
    {
      id: "city",
      label: "City",
      type: "text",
      placeholder: "USA",
    },
  ];
  
  export const productInputs = [
    {
      id: 1,
      label: "Title",
      type: "text",
      placeholder: "Apple Macbook Pro",
    },
    {
      id: 2,
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
    {
      id: 3,
      label: "Category",
      type: "text",
      placeholder: "Computers",
    },
    {
      id: 4,
      label: "Price",
      type: "text",
      placeholder: "100",
    },
    {
      id: 5,
      label: "Stock",
      type: "text",
      placeholder: "in stock",
    },
  ];
  
  // export const meetingInputs = [
  //   { id: "title",
  //       label: "Meeting Title",
  //       type: "text",
  //       name:"title",
        
  //       placeholder: "Add title of meeting",
  //     },
  //     { id: "dec",
  //       label: "Meeting Description",
  //       type: "text",
  //       name:"desc",
        
  //       placeholder: "Add more about meeting",
  //     },      
  //   {
  //     id: "place",
  //     label: "Place",
  //     type: "text",
  //     name:"host",
      
  //     placeholder: "Meeting place",
  //   },
  //   {
  //       id: "host",
  //       label: "Host",
  //       type: "text",
  //       name:"place",
       
  //       placeholder: "Host by",
  //     },
    
  // ];
  
  export const membersInputs = [
    {
      id: "organisation",
      label: "Organisation Name",
      type: "text",
      placeholder: "Canterbury-Bankstown Council",
    },
    {
      id: "phone",
      label: "Phone",
      type: "text",
      placeholder: "Enter Contact Number",
    },
    {
      id: "webUrl",
      label: "Web Address",
      type: "text",
      placeholder: "www.CBMIA.com.au",
    },
  ];

  export const resourcesInputs = [
    {
      id: "name",
      label: "Title of the file",
      type: "text",
      placeholder: "Title",
    },
    {
      id: "category",
      label: "Enter Category of your File",
      type: "text",
      placeholder: "Meeting Minutes",
    },
  ];

  export const galleryInputs = [
    {
      id: "name",
      label: "Title",
      type: "text",
      placeholder: "Title of your Image",
    },
    {
      id: "file",
      label: "Uplaod Image",
      type: "file",
      placeholder: "Uplaod Image",
    },
    
  ];