import react from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import Login from "./Pages/Login/Login.js"
import Home from "./Pages/Home/Home.js"
import Meeting from "./Pages/Meeting/meeting.js";
import Single from "./Pages/Single/Single.js"
import { AuthContext } from "./context/AuthContext.js";
import List from "./Pages/Lists/Lists.js";
import NewMeetings from "./Pages/NewMeetings/NewMeetings.js"
import NewResources from "./Pages/NewResources/NewResources.js";
import NewGallery from "./Pages/NewGallery/NewGallwery.js";
import NewMembers from "./Pages/NewMembers/NewMembers.js"
import "./styles/global.scss";
import { meetingColumns, membersColumns, resourcesColumns, galleryColumns} from "./datatablesource.js";
function App() {

  const ProtectedRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (!user) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  return (
    <div className="App">
        <BrowserRouter>
        <Routes>
        <Route path="/">
          <Route path="login" element={<Login />} />
          <Route index element={ 
          <ProtectedRoute>
             <Home />
          </ProtectedRoute> }/>
          
          <Route path="members">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <List columns={membersColumns} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="new"
                element={
                  <ProtectedRoute>
                 <NewMembers />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route path="meetings">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <List columns={meetingColumns} />
                  </ProtectedRoute>
                }
              />
              
              <Route
                path="new"
                element={
                  <ProtectedRoute>
                 <NewMeetings />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route path="resources">
              <Route
                index
                element={
                  <ProtectedRoute>
                      <List columns={resourcesColumns} />
                  </ProtectedRoute>
                }
              /> 
              <Route
                path="new"
                element={
                  <ProtectedRoute>
                 <NewResources />
                  </ProtectedRoute>
                }
              />
             
            </Route>

            <Route path="gallery">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <NewGallery />
                  </ProtectedRoute>
                }
              /> 
              
             
            </Route>
         
        </Route>
        </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
