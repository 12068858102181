import "../Sidebar/sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});

const Sidebar = () => {

  const { loading, error, user, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogout = async () => {
    // Add a logout function
    try {
      await axiosInstance.post('/auth/logout');
      dispatch({ type: 'LOGOUT' });
      navigate('/login'); // Redirect to the login page or wherever you want after logout
    } catch (err) {
      console.error('Logout failed:', err);
    }
  };
 
  return (
    <div className="sidebar">
      <div className="top">        
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">CBMIA ADMIN</span>
        </Link>
      </div>

      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/" className="listitem" style={{ textDecoration: "none" }}>
          <li>
            <DashboardIcon className="icon" />
            <span className="listItemTitle">Dashboard</span>
          </li></Link>

          <p className="title">LISTS</p>
          <Link to="/members" className="listitem" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span className="listItemTitle">Members</span>
            </li>
          </Link>
          <Link to="/resources" className="listitem" style={{ textDecoration: "none" }}>
            <li>
              <FilePresentOutlinedIcon className="icon" />
              <span className="listItemTitle">Resources</span>
            </li>
          </Link>
          <Link to="/meetings" className="listitem" style={{ textDecoration: "none" }}>
            <li>
              <Diversity3Icon className="icon" />
              <span className="listItemTitle">Meetings</span>
            </li>
          </Link>
          <Link to="/gallery" className="listitem" style={{ textDecoration: "none" }}>
          <li>
            <CameraAltOutlinedIcon className="icon" />
            <span className="listItemTitle">Gallery</span>
          </li>     </Link>    
          <p className="title">USER</p>
          
          <Link to="/" className="listitem" style={{ textDecoration: "none" }} onClick={handleLogout}><li>
            <ExitToAppIcon className="icon" />
            <span className="listItemTitle">Logout</span>
          </li></Link>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
