import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import Axios
import "../Home/home.scss";
import Navbar from "../../Components/Navbar/Navbar";
import Sidebar from '../../Components/Sidebar/Sidebar';
// import Tiles from './Tiles';
import PersonIcon from '@mui/icons-material/Person';
// import CloudCircleIcon from '@mui/icons-material/CloudCircle';

const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});

const Home = () =>{

  const [membersCount, setMembersCount] = useState(0);
  useEffect(() => {
    // Fetch members count from your API endpoint using Axios
    axiosInstance.get('members/count/my') // Replace this with your actual API endpoint
      .then(response => {
        const data = response.data;
        setMembersCount(data.count); // Assuming the count is returned as 'count'
      })
      .catch(error => {
        console.error('Error fetching members count:', error);
      });
  }, []);
 

  // const { data, loading, error } = useFetch("/members/countByType");
  return (
    <>
    <div className='home'>
      <Sidebar />
     <div className="homeContainer">
     <Navbar />

     <div className='welcome'>
     {/* <p className='welcomehead'>Welcome to CBMIA</p> */}
     <p className='head-Contact welcomehead pt-5'><span className="text-danger" id="abt">Welcome</span> <span className="text-info" id='pipe'>| </span><span className="text-warning" id='us'> to CBMIA </span></p>
     <p className='admin'>Admin Portal</p>
      {/* {data && (<div className="pListTitles">
                  <h1>{data?.type}</h1>
                  <h2>{data?.count} {data?.type}</h2>
                </div>)} */}
     </div>
    
    {/* tile */}

 
<div id="projectFacts" className="sectionClass">
    <div className="fullWidth eight columns">
        <div className="projectFactsWrap d-flex justify-content-around  ">
            <div className="itemy ">
            <PersonIcon className="icon" />
                <p className="number">{membersCount}</p>
                <span></span>
                <p className="title">Members</p>
            </div>
            {/* <div className="itemy">
            <CloudCircleIcon className="icon" />
                <p className="number">55</p>
                <span></span>
                <p className="title">Resources</p>
            </div> */}
            </div>
    </div>
</div>

    
    {/* <div className="d-flex justify-content-center ">
  <div className=" mb-4">
    <div className="card align-item-center">
      <div className="card-body text-center">
      <PersonIcon className="icon" />
        <h3><u>150</u></h3>
        <h5>Total</h5>
      </div>
    </div>
  </div>  </div> */}
  {/* <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
    <div className="card">
      <div className="card-body text-center">
        <i className="icon"></i>
        <h3>Total</h3>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid earum doloribus expedita quo voluptas alias laudantium eaque. Vel enim animi suscipit, repelleas!</p>
      </div>
    </div>
  </div>
  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
    <div className="card">
      <div className="card-body text-center">
        <i className="icon"></i>
        <h3>Total</h3>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid earum doloribus expedita quo voluptas alias laudantium eaque. Vel enim animi suscipit, repelleas!</p>
      </div>
    </div>
  </div> */}



{/* <Tiles /> */}

     </div>
    </div>
</>
    
  )
}

export default Home
