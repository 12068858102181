import "../Navbar/navbar.scss"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import image from "../../Images/CBMIAlogo.jpg";
import { Link } from "react-router-dom";

const Navbar = () => {

  return (
    <div className="navbar">
      <div className="wrapper">
       
        {/* <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">CBMIA Admin</span>
        </Link> */}
        <div className="items">
          <div className="item">
            <img
              src={image}
              alt=""
              className="avatar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
