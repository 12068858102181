
import * as React from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';


  
  export const meetingColumns = [
 
    {
      field: "title",
      headerName: "Title",
      width: 330,
    },
    {
      field: "host",
      headerName: "Host",
      width: 150,
    },
    {
      field: "place",
      headerName: "Place",
      width: 200,
    },
    
    
    {
      field: "meeting_date",
      headerName: "Meeting Date",
      width: 250,
    },
    
  ];
  
  export const membersColumns = [
    
    {
      field: "organisation",
      headerName: "Organisation",
      width: 450,
    },
    
    {
      field: "weburl",
      headerName: "Web Address",
      width: 250,
    },
    {
      field: "phone",
      headerName: "Contact number",
      width: 250,
    },
  ];
  export const resourcesColumns = [
   
    {
      field: "name",
      headerName: "File Name",
      width: 350,
    },
    {
      field: "file",
      headerName: " File",
      width: 300,
    },
    {
      field: "category",
      headerName: " Cateogory",
      width: 300,
    }
  ];

  export const galleryColumns = [
    { field: "_id", headerName: "ID", width: 70 },
   
    {
      field: "name",
      headerName: "Title of Image",
      width: 300,
    },
    {
      field: "file",
      headerName: "File",
      width: 230,
    }
  ];