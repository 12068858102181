import "./datatable.scss";
import { DataGrid,GridColDef,GridToolbar} from "@mui/x-data-grid";
import { userColumns, userRows } from "../../datatablesource";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useFetch from "../../Hooks/useFetch.js"
import axios from "axios";

const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});
const Datatable = ({columns}) => {

    const location = useLocation();
    const path = location.pathname.split("/")[1];
    const [list, setList] = useState([]);
    const { data, loading, error } = useFetch(`/${path}`);
 
    useEffect(() => { 
      setList(data);
    },[data]);
  
    const handleDelete = async (id) => {
      try {
        await axiosInstance.delete(`/${path}/${id}`);
        setList((prevList)=> prevList.filter((item) => item._id !== id));
      } catch (err) {}
    };


   
    const actionColumn = [
      {
        field: "action",
        headerName: "Action",
        width: 200,
        renderCell: (params) => {
          return (
            <div className="cellAction">
              
              <div
                className="deleteButton"
                onClick={() => handleDelete(params.row._id)}
              >
                <DeleteOutlineOutlinedIcon/>
              </div>
            </div>
          );
        },
      },
    ];
    

    return (
      <div className="datatable">
        
        <div className="datatableTitle" style={{textTransform:"capitalize"}}>
          {path}
          <Link to={`/${path}/new`} className="link align-item-center">
            Add New {path}
            
          </Link>
        </div>
        
        <DataGrid
          className="datagrid"
          rows={list}      
          columns={columns.concat(actionColumn)}
          pageSize={4}
          rowsPerPageOptions={[4]}
          checkboxSelection
          DensitySelector
          slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
          getRowId={(row) => row._id}
          initialState={{
            ...data.initialState}}
        />
      </div>
    );
  };
  
  export default Datatable;

