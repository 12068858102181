import React from 'react';
import axios from "axios";
import "./login.css";
import image from '../../Images/CBMIAlogo.jpg';
import { AuthContext } from "../../context/AuthContext.js";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});

const Login = () => {
  const [credentials, setCredentials] = useState({
    username: undefined,
    password: undefined,
  });
  const { loading, error, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };
  const handleClick = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axiosInstance.post("/auth/login", credentials);
      if (res.data.isAdmin) {
        dispatch({ type: "LOGIN_SUCCESS", payload: res.data.details });

        navigate("/");
      } else {
        dispatch({
          type: "LOGIN_FAILURE",
          payload: { message: "You are not allowed!" },
        });
      }
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
    }
  };

  return (
    <div className='login'>
        <div className="login-wrapper">
        <a className="brand" href="/">
    <img src={image} width="30" height="30" className="logo " alt="" />  </a>
        <input
          type="text"
          placeholder="username"
          id="username" 
          onChange={handleChange}        
          className="input"
        />  
        <input
          type="password"
          placeholder="password"
          id="password"
          onChange={handleChange}
          className="input"
        />  
        <button disabled={loading} type="submit" onClick={handleClick} className="lButton">
         Login
      </button>
       <p calssname="reset-pass">You forgot your password, no worries !.....<a href='/' >Reset Password</a> </p>
       {error && <span>{error.message}</span>}
        </div>        
    </div>
  )
}

export default Login
