import React, { useState } from 'react'
import { resourcesInputs } from '../../formsource'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Navbar from '../../Components/Navbar/Navbar';
import Sidebar from '../../Components/Sidebar/Sidebar';
import axios from "axios";
import "./newresources.scss";
// import useFetch from "../../Hooks/useFetch"

const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});

const NewResources = () => {

    const [file, setFiles] = useState(""); //file upload
    const [info, setInfo] = useState({});  //input values
    // const { data, loading, error } = useFetch("/resources");
    //event target values passing to info state to post data on button click
    const handleChange = (e) => {
      console.log(e.target.value)
        setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
        console.log(info.name)
        console.log(info.category)
      };
     
 //post request on button click
 const handleClick = async (e) => {
    e.preventDefault();
    try {
     
          const data = new FormData();
          data.append("file", file)
          data.append('name', info.name); // Replace with actual data
          data.append('category', info.category); // Replace with actual data
         
          const uploadRes = await axiosInstance.post('/resources/uploads', data,
          {
            headers:{"Content-Type":"multipart/form-data"},
          }
          );  alert('File uploaded successfully');     
          const { url } = uploadRes.data;        
          return url;
          // setInfo({});
    } catch (err) {console.log(err)
      console.error('Error creating meeting:', err.response);
      if (err.response.data.message) {
      alert(err.response.data.message)}
      else {alert("please logout and login")}}
  };



  return (
    <div className='new'>
        <Sidebar />
       <div className="newContainer">
       <Navbar />

       <div className="top">
        <h1>Add Resources File</h1>
      </div>
        <div className="bottom">
        <div className="right">
          <form >
         
         {resourcesInputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    onChange={handleChange}
                    type={input.type}
                    placeholder={input.placeholder}
                    value={info[input.id] || ''}
                  />
                </div>
              ))}
              
          <div className="formInput">
                <label htmlFor="file">
                  Attach your File  <CloudUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFiles(e.target.files[0])}
                  style={{ display: "none" }} 
                />
             
             

              </div>
              <button onClick={handleClick}>Upload</button>

          </form>
        </div>


       </div>
       </div></div>
  )
}

export default NewResources
