import React, {useState} from 'react'
import axios from 'axios';
import Navbar from '../../Components/Navbar/Navbar';
import Sidebar from '../../Components/Sidebar/Sidebar';
import "./newmeetings.scss"

const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});


export default function NewMeetings() {
 // State to manage form data
 const [formData, setFormData] = useState({
  title: '',
  desc:'',
  place:'',
  host:'',
  meeting_date: '',
  meeting_time: {
    from: '',
    to: '',
  },
});
// Function to handle input changes
const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({
    ...formData,
    [name]: value,
  });
};
// Function to handle time input changes
const handleTimeChange = (e) => {
  const { name, value } = e.target;
  setFormData({
    ...formData,
    meeting_time: {
      ...formData.meeting_time,
      [name]: value,
    },
  });
};
const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    // Send POST request to the backend
    const response = await axiosInstance.post('/meetings', formData);
    console.log('Meeting created:', response.data);

  
    // Reset the form
    setFormData({
      title: '',
      desc:'',
      place:'',
      host:'',
      meeting_date: '',
      meeting_time: {
        from: '',
        to: '',
      },
    });
    alert("Meeting created Successfully!")
  } catch (error) {
    console.error('Error creating meeting:', error.response);
    alert("please logout and login")
  }
};


  return (
    <div className="new">
    <Sidebar />
    <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Add New Meetings</h1>
        </div>
        <div className="bottom">
        <div className="right">
    
    <form onSubmit={handleSubmit}>
    
    <div className="formInput">
      <label>Title:</label>
      <input type="text" name="title" value={formData.title} onChange={handleChange} required />
      
      <label>Desc:</label>
      <input type="text" name="desc" value={formData.desc} onChange={handleChange} required />

      <label>Host:</label>
      <input type="text" name="host" value={formData.host} onChange={handleChange} required />

      <label>Place:</label>
      <input type="text" name="place" value={formData.place} onChange={handleChange} required />
  </div>
  
  <div className="formInput">
      <label>Date:</label>
      <input
        type="date"
        name="meeting_date"
        value={formData.meeting_date}
        onChange={handleChange}
        required
      />

      <label>Time (From):</label>
      <input
        type="time"
        name="from"
        value={formData.meeting_time.from}
        onChange={handleTimeChange}
        required
      />

      <label>Time (To):</label>
      <input
        type="time"
        name="to"
        value={formData.meeting_time.to}
        onChange={handleTimeChange}
        required
      />
</div>
      <button type="submit">Create Meeting</button>
    </form>

    {/* Button to trigger getting meetings */}

  </div>
  </div>
  </div>
</div>
  )
}
